import {supported, get, create} from "@github/webauthn-json";

if (!supported) {
    alert("Webauthn is not supported on this device.");
    return;
}

async function login(username) {
    const urlSuffix = username === undefined ? "" : "?username=" + encodeURIComponent(username);
    const response = await fetch("/login/init" + urlSuffix, {
        method: "POST"
    });
    if (!response.ok) {
        return;
    }
    const credOpts = await response.json();
    const cred = await get({
        "publicKey": credOpts
    });
    await fetch("/login/verify" + urlSuffix, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(cred)
    });
}

async function register(name, isResident) {
    const url = isResident ? "/register/init?resident" : "/register/init";
    const response = await fetch(url, {
        method: "POST"
    });
    if (!response.ok) {
        if (response.status == 401) {
            return false;
        }
        alert("Failed to initiate registration ceremony");
        return true;
    }
    const credOpts = await response.json();
    const cred = await create({
        "publicKey": credOpts
    });
    const response2 = await fetch("/register/verify/" + encodeURIComponent(name), {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(cred)
    });
    if (!response2.ok) {
        alert("Registration failed.");
        return true;
    }
    return true;
}

async function accept_challenge(type, challenge) {
    const response = await fetch("/" + encodeURIComponent(type) + "/accept/" + encodeURIComponent(challenge), {
        method: "POST",
    });
    if (!response.ok) {
        if (response.status == 403) {
            alert("You are not authorised to sign into other services.");
            return;
        }
        alert("Failed to complete the " + type + " challenge.");
        return;
    };
    const result = await response.json()
    window.location = result.redirect_to;
}

async function list_creds() {
    const response = await fetch("/credentials");
    if (!response.ok) {
        if (response.status == 401) {
            return false;
        }
        alert("Failed to fetch registered credentials");
        return true;
    }
    const creds = await response.json();
    const table = document.getElementById("credentials-body");
    creds.forEach(cred => {
        let row = table.insertRow();
        let name = row.insertCell(0);
        name.innerText = cred;
    });
    document.getElementById("unauthenticated").style.display = "none";
    document.getElementById("authenticated").style.display = "";
    await authenticated();
    return true;
}

async function main() {
    const params = new URLSearchParams(window.location.search);

    if (params.has("token")) {
        await fetch("/login/token/" + encodeURIComponent(params.get("token")), {
            method: "POST"
        });
        window.location.href = "/";
    };

    const authenticated = await list_creds()

    if (!authenticated) {
        await login();
        const authenticated2 = await list_creds();
        if (!authenticated2) {
            alert("Login failed. Enter your username or, if you're using resident credentials, refresh the page to try again.");
            return;
        }
    }
}

async function authenticated() {
    const params = new URLSearchParams(window.location.search);

    if (params.has("login_challenge")) {
        await accept_challenge("login", params.get("login_challenge"));
    };
    if (params.has("consent_challenge")) {
        await accept_challenge("consent", params.get("consent_challenge"));
    };
}

document.getElementById("login").addEventListener("submit", async (e) => {
    e.preventDefault();
    await login(document.getElementById("loginUsername").value);
    const authenticated = await list_creds();
    if (!authenticated) {
        alert("Login failed. Refresh the page to try again.");
        return;
    }
});

document.getElementById("newCredential").addEventListener("submit", async (e) => {
    e.preventDefault();
    await register(
        document.getElementById("newCredentialName").value,
        document.getElementById("newCredentialIsResident").checked
    );
    window.location.reload();
});

document.getElementById("newToken").addEventListener("submit", async (e) => {
    e.preventDefault();
    const response = await fetch("/token", {
        method: "POST"
    });
    if (!response.ok) {
        alert("Failed to generate a login URL.");
        return;
    }
    const token = await response.json();
    const url = new URL("/?token=" + encodeURIComponent(token), window.location.href);
    alert(url.href);
});

main()

